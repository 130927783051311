<template>
    <section class="content">
        <div class="card mb-0">
            <div style="padding: 10px;">
                <form role="form" @submit="submitForm">
                        <div class="alert alert-warning" style="font-size:14px;color: white;" role="alert">
                            <strong><i class="fas fa-info-circle" style="font-size:16px;"></i>&nbsp;Perhatian!</strong>
                            Saat ini anda belum tergabung ke dalam program belajar di Taruna Cendekia. Silahkan lengkapi formulir berikut untuk registrasi.
                        </div>
                        <p v-if="errors.length" class="alert alert-danger">
                            <b>Please correct the following error(s):</b>
                            <ul>
                                <li v-for="error in errors">{{ error }}</li>
                            </ul>
                        </p>
                        <div class="row">
                            <div class="card" style="width:100%">
                              <div class="card-header d-flex p-0">
                                <ul class="nav nav-pills mr-auto p-2">                                  
                                  <li class="nav-item"><a class="nav-link active" href="#tab_1" data-toggle="tab">Umum</a></li>
                                  <li class="nav-item"><a class="nav-link" href="#tab_5" data-toggle="tab">Program</a></li>
                                  <li class="nav-item"><a class="nav-link" href="#tab_2" data-toggle="tab">Sekolah</a></li>
                                  <li class="nav-item"><a class="nav-link" href="#tab_3" data-toggle="tab">Fisik</a></li>
                                  <li class="nav-item"><a class="nav-link" href="#tab_4" data-toggle="tab">Family</a></li>                                  
                                </ul>
                              </div><!-- /.card-header -->
                              <div class="card-body">
                                <div class="tab-content">
                                  <div class="tab-pane active" id="tab_1">
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Gender</label>
                                            <select v-model="form.gender" class="form-control">
                                                <option v-for="item in genders" v-bind:value="item.id">{{ item.label }}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6 form-group" style="display: none;">
                                            <label for="inputFile">FOTO</label>
                                            <input type="hidden" name="img_code" value="" v-model="form.img_code" id="img_code" />
                                            <div class="custom-file">
                                                <input type="file" class="custom-file-input inputfile" id="inputFile"  name="file" @change="uploadFotoProfile(this)">
                                                <label class="custom-file-label" for="inputFile">Choose file</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Birth date</label>
                                            <datepicker v-model="form.birth_date" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Birth Place</label>
                                            <input id="birth_place" class="form-control" v-model="form.birth_place" type="text" name="i_birth_place" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Alamat</label>
                                            <input id="alamat" class="form-control" v-model="form.alamat" type="text" name="i_alamat" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">No KTP</label>
                                            <input id="no_ktp" class="form-control" v-model="form.no_ktp" type="number" name="i_no_ktp" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">No KK</label>
                                            <input id="no_kk" class="form-control" v-model="form.no_kk" type="number" name="i_no_kk" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">No HP</label>
                                            <input id="no_hp" class="form-control" v-model="form.no_hp" type="number" name="i_no_hp" />
                                        </div>
                                    </div>
                                  </div>
                                  <!-- /.tab-pane -->
                                  <div class="tab-pane" id="tab_2">
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Asal Sekolah</label>
                                            <input id="asal_sekolah" class="form-control" v-model="form.asal_sekolah" type="text" name="i_asal_sekolah" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Jurusan Sekolah</label>
                                            <input id="jurusan_sekolah" class="form-control" v-model="form.jurusan_sekolah" type="text" name="i_jurusan_sekolah" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Lulus Sekolah</label>
                                            <datepicker v-model="form.lulus_sekolah" :options="{format:'yyyy', startView: 2,minViewMode: 2}" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Kuliah</label>
                                            <input id="kuliah" class="form-control" v-model="form.kuliah" type="text" name="i_kuliah" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Fakultas</label>
                                            <input id="fakultas" class="form-control" v-model="form.fakultas" type="text" name="i_fakultas" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Jurusan</label>
                                            <input id="jurusan" class="form-control" v-model="form.jurusan" type="text" name="i_jurusan" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Semester</label>
                                            <input id="semester" class="form-control" v-model="form.semester" type="text=" name="i_semester" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Tujuan Dinas</label>
                                            <input id="tujuan_dinas" class="form-control" v-model="form.tujuan_dinas" type="text" name="i_tujuan_dinas" />
                                        </div>
                                    </div>
                                  </div>
                                  <!-- /.tab-pane -->
                                  <div class="tab-pane" id="tab_3">
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Fisik Tinggi</label>
                                            <input id="fisik_tinggi" class="form-control" v-model="form.fisik_tinggi" type="number" name="i_fisik_tinggi" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Fisik Berat</label>
                                            <input id="fisik_berat" class="form-control" v-model="form.fisik_berat" type="number" name="i_fisik_berat" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Fisik Mata</label>
                                            <input id="fisik_mata" class="form-control" v-model="form.fisik_mata" type="text" name="i_fisik_mata" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Fisik Kacamata</label>
                                            <input id="fisik_kacamata" class="form-control" v-model="form.fisik_kacamata" type="text" name="i_fisik_kacamata" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Fisik Kelainan</label>
                                            <input id="fisik_kelainan" class="form-control" v-model="form.fisik_kelainan" type="text" name="i_fisik_kelainan" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Fisik Penyakit</label>
                                            <input id="fisik_penyakit" class="form-control" v-model="form.fisik_penyakit" type="text" name="i_fisik_penyakit" />
                                        </div>
                                    </div>
                                  </div>
                                  <div class="tab-pane" id="tab_4">
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Nama Keluarga</label>
                                            <input id="family_name" class="form-control" v-model="form.family_name" type="text" name="i_family_name" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Jenis Kelamin</label>
                                            <select v-model="form.family_gender" class="form-control">
                                                <option v-for="item in genders" v-bind:value="item.id">{{ item.label }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Tipe Relasi</label>
                                            <input id="relation_type" class="form-control" v-model="form.relation_type" type="text" name="i_relation_type" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Birth Date</label>
                                            <datepicker v-model="form.family_birth_date" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Birth Place</label>
                                            <input id="family_birth_place" class="form-control" v-model="form.family_birth_place" type="text" name="i_family_birth_place" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Pendidikan Terakhir</label>
                                            <input id="family_education" class="form-control" v-model="form.family_education" type="text" name="i_family_education" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <label class="control-label">Pekerjaan</label>
                                            <input id="family_job" class="form-control" v-model="form.family_job" type="text" name="i_family_job" />
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <label class="control-label"> </label>
                                            <label class="checkbox control-label">
                                                <input 
                                                    type="checkbox" 
                                                    name="active" 
                                                    v-model="form.status"
                                                    true-value="1"
                                                    false-value="0"
                                                    />
                                             Active</label>
                                        </div>
                                    </div>
                                  </div>
                                  <div class="tab-pane" id="tab_5">
                                    <div class="row">
                                        <div class="col-md-12 form-group">
                                            <label class="control-label">Pilih program</label>
                                            <v-select :options="optProgram" v-model="form.program_id" :reduce="opt => opt.id" @input="calculate"/>
                                        </div>
                                        <div class="col-sm-12">
                                            <div style="width: 100%;height: auto;background-color: #9D9D9D;color: white;font-weight: 500;font-size: initial;padding: 20px;border-radius: 4px;margin-bottom: 12px;">
                                                Biaya : {{form.nilai_total}}
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                  <!-- /.tab-pane -->
                                </div>
                                <!-- /.tab-content -->
                              </div><!-- /.card-body -->
                            </div>
                            <!-- ./card -->
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary">Save changes</button>
                        <router-link to='/' class="btn btn-secondary">Close
                        </router-link>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>
<script>
    import { auth, authFetch, createTable } from "@/libs/hxcore";
    import moment from "moment";
    import vSelect from 'vue-select';
    import "vue-select/dist/vue-select.css";
    import datepicker from '@/components/Datepicker';
    import $ from 'jquery';
    import Swal from "sweetalert2";

    let idr = Intl.NumberFormat("en-ID", {
        style: "currency",
        currency: "IDR",
        useGrouping: true,
        maximumSignificantDigits: 3,
    });

    export default {
        name: 'CompleteRegistration',
        components: {
            vSelect,
            datepicker
        },
        data() {
            return {
                programId: '',
                errors: [],
                method: '',
                roles: '',
                groups: [],
                optProgram: [],
                optProgramDetail: [],
                genders: [
                    {id: 'L', label: 'Laki-laki'},
                    {id: 'P', label: 'Perempuan'}
                ],
                formTitle: 'Tambah User',
                formUpload: {
                    id: '',
                    code: '',
                    nominal: 0,
                    nilai: 0,
                    pembayaran: 0,
                    kekurangan: 0
                },
                formDoUpload: {
                    id: '',
                    code: '',
                    nominal: 0
                },
                form: {
                    username : '',
                    role_id : '',
                    display_name : '',
                    email : '',
                    gender : '',
                    birth_date : '',
                    birth_place : '',
                    alamat : '',
                    no_ktp : '',
                    no_kk : '',
                    no_hp : '',
                    asal_sekolah : '',
                    jurusan_sekolah : '',
                    lulus_sekolah : '',
                    kuliah : '',
                    fakultas : '',
                    jurusan : '',
                    semester : '',
                    tujuan_dinas : '',
                    fisik_tinggi : '',
                    fisik_berat : '',
                    fisik_mata : '',
                    fisik_kacamata : '',
                    fisik_kelainan : '',
                    fisik_penyakit : '',
                    relation_type : '',
                    family_name : '',
                    family_gender : '',
                    family_birth_date : '',
                    family_birth_place : '',
                    family_education : '',
                    family_job : '',
                    status: '',
                    program_id: '',
                    id:'',
                    nominal_angsuran: 0,
                    jumlah_angsuran: 0,
                    keterangan_angsuran: '',
                    nilai_total: 0,
                    img_code: '',
                    siswa_registrasi_id: ''
                },
                formItem: {
                    waktu: '',
                    nominal: '',
                },
            };
        },
        created: function () {
            this.url = window.$apiUrl;
            this.programId = this.$route.params.program;
            var user = auth.user();

            authFetch('/masterdata/siswa/info/'+user.id)
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.info = js.data;
                this.form.siswa_registrasi_id = this.info.id_registrasi
                authFetch('/masterdata/siswa_register/info/'+this.info.id_siswa)
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    this.form = js;
                })
            })
            authFetch('/masterdata/siswa_register/groups')
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.groups = js.data;
            })
            authFetch('/masterdata/siswa_register/program_online')
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    this.optProgram = js.data;
                    this.form.program_id = this.programId;
                })
            authFetch('/masterdata/siswa_register/program_detail')
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {
                    this.optProgramDetail = js.data;
                    if (this.programId) {
                        this.form.nilai_total = idr.format(this.optProgramDetail[this.programId])
                    }
                })
        },
        mounted() {
            const e = this.$refs;
            var self = this;
            this.tbl = createTable(e.tableuser, {
                title: "",
                serverSide: false,
                filter: false,
                ajax: "/pr/siswa_plan_angsuran/skemabayar",
                order: [[ 0, "desc" ]],
                lengthChange: false,
                paging: false,
                info: false, 
                columns: [
                    { data: "waktu",
                      type: "date"},
                    { data: "nominal" },
                    { data: "action",
                    sortable: false,
                    render: function (data, row, type, meta) {
                      return '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Delete</a></div>';
                    } },
                ],
                rowCallback: function (row, data) {}
            }).fnEditor({
                action: '<div class="btn-group"><a class="btn edit btn-xs icon-edit btn-primary"><i class="fas fa-pen"></i> Edit</a><a class="btn delete btn-xs icon-remove btn-danger"><i class="fa fa-trash"></i> Hapus</a></div>'
            })
        },
        methods: {
            handleClick(e) {
                if (e.target.matches('.link-role')) {
                    this.$router.push({ path: '/permit/' + e.target.dataset.id })
                    return false;
                }
            },
            calculate: function(program) {
                this.form.nilai_total = idr.format(this.optProgramDetail[program])
            },
            addItem: function(ev) {
                var nRow = this.tbl.fnAddRow(this.formItem);
                var aData = {
                  waktu: this.formItem.waktu,
                  nominal: this.formItem.nominal,
                };
                this.tbl.fnUpdate(aData, nRow);
                $('td .btn.edit', nRow).click();
                ev.preventDefault();
            },

            uploadFotoProfile : function (file) {
                var file_data = $('#inputFile').prop('files');
                var form_data = new FormData();
                var fileInput = $('#inputFile')[0];
                $.each(fileInput.files, function(k,file){
                  form_data.append('file', file);
                });
                form_data.append('subdir', '/registrasi/');
                // form_data.append('id', this.form.id);
                this.form_data = form_data
                return false
            },
            doUploadProfile: function() {
                return authFetch('/management/cabang/upload_data', {
                    method: 'POST',
                    body: this.form_data,
                    headers: {
                    'Content-Type': null,
                    }
                })
            },
            submitForm: function(ev) {
                const e = this.$refs;
                var self = this;
                var items = [];
                var file_data = $('#inputFile').prop('files');

                if (file_data.length == 0) {
                    var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                    var urlSubmit = '/masterdata/siswa_register';

                    authFetch('/masterdata/siswa_register/lengkapi_data/' + this.info.id_siswa, {
                            method: 'PUT',
                            body: data,
                        })
                        .then(res => {
                            if (res.status === 201) {

                            } else if (res.status === 400) {}
                            return res.json();
                        })
                        .then(js => {

                            this.errors = [];
                            if (!js.success) {
                                for (var key in js) {
                                    if (js.hasOwnProperty(key)) {
                                        this.errors.push(js[key])
                                    }
                                }

                                return;
                            } else {
                                Swal.fire({
                                    icon: 'success',
                                    title: 'Informasi',
                                    text: 'Data telah tersimpan.'
                                })
                                this.$router.push("/complete-payment");
                            }
                            
                        });
                } else {
                    this.doUploadProfile().then((res) => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}

                        return res.json();
                        
                    }).then(js => {
                        this.errors = [];
                        if (!js.status) {
                            console.log(js.details)

                            for (var key in js.details) {
                                if (js.details.hasOwnProperty(key)) {
                                    this.errors.push(js.details[key])
                                }
                            }

                            return;
                        } else {
                            if (js.message[0]) {
                                self.form.img_code = js.message[0].code;
                            }

                            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                            var urlSubmit = '/masterdata/siswa_register';

                            if (this.method == 'PUT') urlSubmit = '/masterdata/siswa_register/' + this.form.id;
                            authFetch(urlSubmit, {
                                    method: this.method,
                                    body: data
                                })
                                .then(res => {
                                    if (res.status === 201) {

                                    } else if (res.status === 400) {}
                                    return res.json();
                                })
                                .then(js => {

                                    this.errors = [];
                                    if (!js.success) {
                                        for (var key in js) {
                                            if (js.hasOwnProperty(key)) {
                                                this.errors.push(js[key])
                                            }
                                        }

                                        return;
                                    } else {
                                        Swal.fire({
                                            icon: 'success',
                                            title: 'Informasi',
                                            text: 'Data telah tersimpan.'
                                        })
                                        this.$router.push("/complete-payment");
                                    }
                                    
                                });
                        }
                    });
                }

                ev.preventDefault();
            },
        }
    }
</script>
<style type="text/css" scoped>
    .section-home {
        background: #F3F3F3;
    }

    .section-home .card-body {
        min-height: 100px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 2rem;
    }
    .form-control {
        height: calc(2rem + 2px);
        /*border:0;*/
    }
</style>